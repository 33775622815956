import css from '@/scss/debugger.scss'
import printCss from '@/scss/debugger.print.scss'
import ClipboardJS from 'clipboard'

type DebugData = {
    time: number,
    caller: string,
    message: string,
    elem?: HTMLElement
}

declare global {
    interface Window {
        Debugger: {
            parseHTML(root: Node | Array<Node>): void
            insert(debug: DebugData | Array<DebugData>): void
        };
    }
}

const regExDebug: RegExp = /\/\*DEBUG(?: (global))? time="(\d+)" caller="([^"]+)" message="([^"]+)" DEBUG\*\//
const body: HTMLElement = document.body
const rootDebugElems: Array<DebugData> = []
let cssLoaded: boolean = false

const pad = (n: number, l: number = 2): string => {
    let r: string = String(n);
    while (r.length < l) {
        r = '0' + r;
    }

    return r;
}

const iso8601Date = (t: number): string => {
    const d: Date = new Date(t * 1000);

    return d.getUTCFullYear()
        + '-' + pad(d.getUTCMonth() + 1)
        + '-' + pad(d.getUTCDate())
        + 'T' + pad(d.getUTCHours())
        + ':' + pad(d.getUTCMinutes())
        + ':' + pad(d.getUTCSeconds());
}

const traverse = (elem: Node | Array<Node>): Array<DebugData> => {
    const elems: Array<DebugData> = []

    const iterator = (e: Node | Array<Node>): boolean => {
        var i;
        if (Array.isArray(e)) {
            for (i = 0; i < e.length; i++) {
                if (iterator(e[i])) {
                    e[i] = elems[elems.length - 1].elem!;
                }
            }

            return false;
        }

        if (e.nodeName === '#text' || e.nodeType === 3) {
            return false;
        }

        if (e.nodeName === '#comment' || e.nodeType === 8) {
            return parse(e as HTMLElement, elems);
        }

        for (i = 0; i < e.childNodes.length; i++) {
            if (iterator(e.childNodes[i])) {
                i--;
            }
        }

        return false
    };

    iterator(elem);
    return elems;
}

const parse = (elem: HTMLElement, elems: Array<DebugData>): boolean => {
    let debugElem: DebugData | false;

    const match = regExDebug.exec(elem.nodeValue!);
    if (!match) {
        return false;
    }

    const debugObj: DebugData = {
        time: Number(match[2]),
        caller: match[3],
        message: match[4]
    };
    const isGlobal = match[1] !== undefined;

    let parentNode: Node | null = elem.parentNode!;
    if (parentNode.nodeName === '#document' || parentNode.nodeType === 9 || isGlobal) {
        debugElem = insertInBody(debugObj);
    } else {
        debugElem = insertInElem(debugObj, elem);
    }

    if (!debugElem) {
        return false;
    }

    elems.push(debugElem);
    if (parentNode !== null) {
        elem.parentNode!.removeChild(elem);
    }
    return true;
}

const createDebugElem = (data: DebugData): HTMLElement | undefined => {
    if (typeof data !== 'object') {
        return;
    }

    const caller: string = data.caller + '@' + iso8601Date(data.time);

    const eDebug: HTMLElement = document.createElement('debug');
    eDebug.className = '__debug __debug_closed';
    eDebug.title = caller;

    const eDebugCount: HTMLElement = document.createElement('header');
    eDebugCount.textContent = '1';
    eDebug.appendChild(eDebugCount);
    addClickEvent(eDebugCount, onClick);

    const eDebugNav: HTMLElement = document.createElement('nav');

    const eDebugNavPrev: HTMLAnchorElement = document.createElement('a');
    eDebugNavPrev.textContent = '<';
    eDebugNavPrev.href = '#prev';
    eDebugNav.appendChild(eDebugNavPrev);
    addClickEvent(eDebugNavPrev, onGoto);

    const eDebugNavItem: HTMLElement = document.createElement('i');
    eDebugNavItem.textContent = '1';
    eDebugNav.appendChild(eDebugNavItem);

    const eDebugNavNext: HTMLAnchorElement = document.createElement('a');
    eDebugNavNext.textContent = '>';
    eDebugNavNext.href = '#next';
    eDebugNav.appendChild(eDebugNavNext);
    addClickEvent(eDebugNavNext, onGoto);

    eDebug.appendChild(eDebugNav);

    createDebugInner(eDebug, data);

    return eDebug;
}

const createDebugInner = (container: HTMLElement, data: DebugData): void => {
    const caller: string = data.caller + '@' + iso8601Date(data.time);
    const i: number = container.childNodes.length - 1;
    const nav: HTMLElement = container.childNodes[1] as HTMLElement;
    if (i > 1) {
        nav.className = '__debug_nav_active';
    } else {
        nav.className = '';
    }

    const eDebugInner: HTMLElement = document.createElement('div');
    eDebugInner.className = '__debug_item_' + i;
    container.appendChild(eDebugInner);

    const eCaller: HTMLElement = document.createElement('caller');
    const copyBtn: HTMLButtonElement = document.createElement('button');
    copyBtn.type = 'button'
    copyBtn.className = '__debug_copy';
    copyBtn.textContent = 'copy';
    eCaller.appendChild(copyBtn);
    new ClipboardJS(copyBtn, {
        target: function (trigger: Element): Element {
            return trigger.parentNode!.nextSibling as Element;
        }
    });
    const eCallerText: Text = document.createTextNode(caller);
    eCaller.appendChild(eCallerText);

    eDebugInner.appendChild(eCaller);

    const eMessage: HTMLElement = document.createElement('code');
    eMessage.innerHTML = data.message;
    eDebugInner.appendChild(eMessage);
}

const insertInElem = (debug: DebugData, sibling: HTMLElement): DebugData | false => {
    let elem: HTMLElement | undefined;

    let p: HTMLElement | null = sibling;

    while ((p = p.previousSibling as HTMLElement) !== null) {
        if (p.nodeName === 'DEBUG') {
            break;
        } else if (p.nodeName === '#text' || p.nodeType === 3) {
            if (p.nodeValue!.trim() === '')
                continue;
            p = null;
            break;
        } else if (p.nodeName === '#comment' || p.nodeType === 8) {
            //continue;
        } else {
            p = null;
            break;
        }

    }

    if (p === null) {
        elem = createDebugElem(debug);
        if (!elem) {
            return false;
        }
        sibling.parentNode!.insertBefore(elem, sibling.nextSibling);
        debug.elem = elem;
    } else {
        elem = p;
        elem.firstChild!.textContent = String(Number((elem.childNodes[0] as HTMLElement).innerHTML) + 1);

        createDebugInner(elem, debug);

        debug.elem = elem;
    }

    return debug;
}

const insertInBody = (debug: DebugData): DebugData | false => {
    let elem: HTMLElement | undefined;

    if (rootDebugElems.length === 0) {
        elem = createDebugElem(debug);
        if (!elem)
            return false;

        body.insertBefore(elem, body.firstChild);

        debug.elem = elem;
    } else {
        elem = rootDebugElems[rootDebugElems.length - 1].elem;
        elem!.childNodes[0].textContent = String(Number((elem!.childNodes[0] as HTMLElement).innerHTML) + 1);

        createDebugInner(elem!, debug);

        debug.elem = elem;
    }
    rootDebugElems.push(debug);

    return debug;
}

const addClickEvent = (elem: HTMLElement, func: (event: MouseEvent) => any) => {
    elem.addEventListener('click', func, false);
}

const onClick = (e: MouseEvent): void | false => {
    if (e.target !== e.currentTarget) {
        return;
    }

    const d: HTMLElement = (e.currentTarget as HTMLElement).parentNode as HTMLElement;

    if (d.className.lastIndexOf('__debug_closed') >= 0) {
        d.className = '__debug __debug_open';
        goto(d);
    } else {
        d.className = '__debug __debug_closed';
        for (let i: number = 2; i < d.childNodes.length; i++) {
            (d.childNodes[i] as HTMLElement).style.display = '';
        }
    }

    return false;
}

const onGoto = (e: MouseEvent) => {
    if (e.target !== e.currentTarget) {
        return false;
    }

    const btn = e.currentTarget as HTMLAnchorElement
    const d: HTMLElement = btn.parentNode!.parentNode as HTMLElement;

    switch (btn.getAttribute('href')) {
        case '#prev':
            goto(d, -1);
            break;
        case '#next':
            goto(d, 1);
            break;
    }

    return false;
}

const goto = (e: HTMLElement, n: number = 0): void => {
    const nav: HTMLElement = e.childNodes[1] as HTMLElement;
    const navPage: HTMLElement = nav.childNodes[1] as HTMLElement;
    const itemCount: number = e.childNodes.length - 2;

    n += Number(navPage.innerHTML);

    if (n < 1) {
        n = itemCount - n;
    } else if (n > itemCount) {
        n = n - itemCount;
    }

    navPage.textContent = pad(n, 3)
    for (let i: number = 2; i < e.childNodes.length; i++) {
        const elem: HTMLElement = e.childNodes[i] as HTMLElement
        if (i - 1 === n) {
            elem.className = '__debug_item_' + (i - 1) + ' __debug_item_open';
        } else {
            elem.className = '__debug_item_' + (i - 1) + ' __debug_item_closed';
        }
    }
}

const injectCSS = (): void => {
    if (cssLoaded) {
        return;
    }

    const style: HTMLStyleElement = document.createElement('style');
    style.setAttribute('type', 'text/css');
    style.media = 'screen';
    style.innerHTML = css;
    document.head.appendChild(style);

    const stylePrint: HTMLStyleElement = document.createElement('style');
    stylePrint.setAttribute('type', 'text/css');
    stylePrint.media = 'print';
    stylePrint.textContent = printCss;
    document.head.appendChild(stylePrint);
    cssLoaded = true;
}

const injectToggler = (): void => {
    if (body.children[0].nodeName === 'DEBUGTOGGLE') {
        return;
    }

    const toggler: HTMLElement = document.createElement('debugtoggle');
    const togglerTag: HTMLElement = document.createElement('toggle');
    togglerTag.appendChild(document.createTextNode('OFF'));
    addClickEvent(togglerTag, () => {
        const bodyClasses: Array<string> = body.className !== '' ? body.className.split(' ') : [];
        const debuggerClassPos: number = bodyClasses.indexOf('debugger-enabled');
        if (debuggerClassPos >= 0) {
            togglerTag.innerHTML = 'OFF';
            bodyClasses.splice(debuggerClassPos, 1);
        } else {
            togglerTag.innerHTML = 'ON';
            bodyClasses.push('debugger-enabled');
        }
        body.className = bodyClasses.join(' ');
    });
    toggler.appendChild(togglerTag);

    body.insertBefore(toggler, body.firstChild);

    doubleTapHandler(togglerTag);
}

const doubleTapHandler = (toggler: HTMLElement): void => {
    const delta: number = 500;
    let lastKeypressTime: number = 0;
    const cb = (e: KeyboardEvent) => {
        if (e.key === 'Control') {
            let thisKeypressTime: number = (new Date()).getTime();
            if (thisKeypressTime - lastKeypressTime <= delta) {
                toggler.click();
                thisKeypressTime = 0;
            }
            lastKeypressTime = thisKeypressTime;
        }
    };

    document.addEventListener('keyup', cb, false);
}

const parseHTML = (root: Node | Array<Node>): void => {
    var debugElems = traverse(root);

    if (debugElems) {
        injectToggler();
        injectCSS();
    }
}

const insert = (debug: DebugData | Array<DebugData>): void => {
    if (!Array.isArray(debug)) {
        debug = [debug];
    }

    for (let i: number = 0; i < debug.length; i++) {
        insertInBody(debug[i]);
    }
}

window.Debugger = {
    parseHTML,
    insert
};

if (document.body.className.split(' ').indexOf('no-debug') < 0) {
    parseHTML(document);
}